(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/external-systems-common/assets/javascripts/utils.js') >= 0) return;  svs.modules.push('/components/sport/external-systems-common/assets/javascripts/utils.js');
"use strict";


let logger;
let ErrorMessages;
if (svs.isServer) {
  const trinidad = require('trinidad-core').core;
  logger = trinidad.logger('sport:external-system-upload');
  const path = require('path');
  ErrorMessages = require(path.join(__dirname, 'constants.es6')).ErrorMessages;
} else {
  ErrorMessages = svs.components.sport.externalSystemsCommon.constants.ErrorMessages;
  logger = new svs.core.log.Logger('sport:external-system-upload');
}
const getErrorMessage = function (key) {
  let defaultMessage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return ErrorMessages[key] || defaultMessage;
};
function addErrorMessage(req, error) {
  const flashData = {
    title: 'Något gick fel',
    message: getErrorMessage(error, 'Försök igen.'),
    area: 'popup',
    branding: 'sport'
  };
  req.flash('error', flashData);
  logger.error(getErrorMessage(error, 'Try again.'), req);
}
const toExport = {
  addErrorMessage,
  getErrorMessage
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sport.externalSystemsCommon.utils', toExport);
}

 })(window);