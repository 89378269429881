(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sport/external-systems-common/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/sport/external-systems-common/assets/javascripts/constants.js');
"use strict";


const status = {
  NONE: 'NONE',
  OK: 'OK',
  TOO_BIG: 'TOO_BIG',
  WRONG_EXTENSION: 'WRONG_EXTENSION',
  TOO_FEW_LINES: 'TOO_FEW_LINES',
  WRONG_PRODUCTNAME: 'WRONG_PRODUCTNAME',
  ERROR: 'ERROR',
  NOT_FOUND: 'NOT_FOUND',
  PARSE_ERROR: 'PARSE_ERROR',
  PAID: 'PAID',
  NO_OPEN_OR_MATCHING_DRAW: 'NO_OPEN_OR_MATCHING_DRAW',
  WRONG_JOKER_AMOUNT: 'WRONG_JOKER_AMOUNT',
  WRONG_JOKER_LENGTH: 'WRONG_JOKER_LENGTH',
  SYSTEM_INCORRECT: 'SYSTEM_INCORRECT',
  LIMIT_FILE_SIZE: 'LIMIT_FILE_SIZE',
  PRODUCT_MISMATCH: 'PRODUCT_MISMATCH',
  10001: 'WAGER_ID_MISSING',
  10002: 'WAGER_SERIAL_MISSING',
  10003: 'CLIENT_INFO_MISSING',
  10004: 'RETAILER_INFO_MISSING',
  10005: 'NO_OPEN_BOMBEN_DRAW',
  10006: 'DRAWNO_BOMBENNO_CONFLICT',
  10007: 'WAGER_NOT_FOUND',
  10008: 'NO_OPEN_OR_MATCHING_DRAW',
  10009: 'COULD_NOT_FETCH_WAGER',
  10010: 'COULD_NOT_UPDATE_WAGER',
  10011: 'COULD_NOT_SAVE_WAGER',
  10012: 'INVALID_FORMAT',
  10013: 'INVALID_DAY_FORMAT',
  10014: 'MAX_LINES',
  10015: 'BET_AMOUNT_NOT_VALID',
  10016: 'INVALID_ROW_LENGTH',
  10017: 'VALUES_IN_ROW_NOT_UNIQUE',
  10018: 'SYSTEM_NOT_VALID',
  10019: 'SYSTEM_NOT_VALID_PRODUCT',
  10020: 'WAGER_ITEM_MISSING',
  10021: 'TOTAL_COST_TO_HIGH',
  10022: 'WAGER_NULL',
  10023: 'WAGER_TIMEOUT',
  10024: 'JOKER_INVALID',
  10025: 'JOKER_INVALID_LENGTH',
  10026: 'JOKER_INVALID_AMOUNT'
};
const ErrorMessages = {
  TOO_BIG: 'Filen du valde är för stor.',
  WRONG_EXTENSION: 'Filen du valde måste vara av typen txt.',
  TOO_FEW_LINES: 'Filen du valde är inte korrekt, den innehåller för få rader.',
  WRONG_PRODUCTNAME: 'Produktnamnet verkar inte stämma. I filen ska första ordet vara en produkt som Sport & Casino erbjuder Externa Systemspel på. Tänk på att stava rätt.',
  ERROR: 'Försök igen senare.',
  NOT_FOUND: 'Filen du valde måste vara av typen txt.',
  PARSE_ERROR: 'Det gick inte att läsa ut data från filen. Kontrollera att den är korrekt formaterad.',
  PAID: 'Filen du valde måste vara av typen txt.',
  BET_AMOUNT_NOT_VALID: 'Den angivna insatsen är inte tillåten.',
  NO_OPEN_BOMBEN_DRAW: 'Det finns ingen öppen Bomben-omgång med det bombennumret.',
  INVALID_FORMAT: 'Filen du valde är inte korrekt.',
  INVALID_DAY_FORMAT: 'Filen du valde är inte korrekt.',
  MAX_LINES: 'Filen innehåller för många rader.',
  INVALID_ROW_LENGTH: 'Filen du valde innehåller för många eller för få matcher på en eller flera rader.',
  VALUES_IN_ROW_NOT_UNIQUE: 'Filen du valde är inte korrekt.',
  SYSTEM_NOT_VALID: 'Filen innehåller ett eller flera för stora M-system.',
  SYSTEM_NOT_VALID_PRODUCT: 'Det går endast att spela enkelrader på Måltipset.',
  TOTAL_COST_TO_HIGH: 'Totalkostnaden är för stor, försök med ett mindre system.',
  WAGER_TIMEOUT: 'En timeout har skett. Var god försök igen.',
  NO_OPEN_OR_MATCHING_DRAW: 'Omgångsnumret finns inte eller så finns det ingen spelbar omgång på det angivna spelet.',
  SYSTEM_INCORRECT: 'Systemet du skickade in är inte korrekt.',
  PRODUCT_MISMATCH: 'Produktnamnet verkar inte stämma. Dubbelkolla att första ordet är detsamma för den produkt du är inne på just nu.'
};
const toExport = {
  status,
  ErrorMessages
};
if (svs.isServer) {
  module.exports = toExport;
} else {
  setGlobal('svs.components.sport.externalSystemsCommon.constants', toExport);
}

 })(window);